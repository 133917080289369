import { useAxios } from '@vue-composable/axios';
import { ref } from '@vue/composition-api';
import { PolicyFileUploadAPI } from '../api';
import { FileUploadStatus } from '../constants';
import { PostPolicyData, PostPolicyFile, TFile } from '../types';

export function usePolicyFileUpload(pipelineId: string) {
    const { exec } = useAxios(true);
    const fileProgress = ref<{ id: number; current: number }>({
        id: -1,
        current: 0,
    });

    const uploadPolicyFiles = async (
        files: { file: TFile; policy: PostPolicyData; sample?: boolean }[],
    ): Promise<void> => {
        for (const [idx, value] of files.entries()) {
            const file = value.file;

            if (!file || file.status === FileUploadStatus.Pending) continue;

            const postPolicy = await getPostPolicy(value.policy);
            fileProgress.value.current = 0;
            fileProgress.value.id = idx;

            await exec(
                PolicyFileUploadAPI.uploadPolicyFile(file, `${file.name}`, postPolicy, (progressEvent) => {
                    fileProgress.value.current = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                }),
            );

            const data = {
                filename: file.name,
                mimeType: file.type || 'application/octet-stream',
                path: postPolicy.prefix,
                size: file.size,
            };

            if (value.sample) {
                await storeSampleFileDetails([data]);
            } else {
                await storeTaskFilesDetails([data]);
            }
        }
    };

    const uploadSampleFileFromData = async (sample: {
        data: any;
        name: string;
        type: string;
        policy: PostPolicyData;
    }): Promise<void> => {
        const postPolicy = await getPostPolicy(sample.policy);

        await exec(PolicyFileUploadAPI.uploadPolicyFile(sample.data, sample.name, postPolicy));

        const data = {
            filename: sample.name,
            mimeType: sample.type,
            path: postPolicy.prefix,
            size: encodeURI(sample.data).split(/%..|./).length - 1,
        };

        await storeSampleFileDetails([data]);
    };

    const getPostPolicy = async (policyData: PostPolicyData): Promise<any> => {
        return (await exec(PolicyFileUploadAPI.getPostPolicy(pipelineId, policyData.folder, policyData.subfolder)))
            ?.data;
    };

    const storeSampleFileDetails = async (data: PostPolicyFile[]): Promise<void> => {
        await exec(PolicyFileUploadAPI.storeSampleFileDetails(pipelineId, data));
    };

    const storeTaskFilesDetails = async (data: PostPolicyFile[], taskName: string = 'harvester'): Promise<void> => {
        await exec(PolicyFileUploadAPI.storeTaskFilesDetails(pipelineId, data, taskName));
    };

    return {
        uploadPolicyFiles,
        uploadSampleFileFromData,
        fileProgress,
    };
}
