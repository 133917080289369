import { computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { constraintOptions, ConstraintOptionsType } from '../constants';
import { ConstraintType } from '../types/cleaning.type';

export function useCleaningConstraints(configuration: any, selectedFields: Ref<any[]>) {
    const selectedFieldsTypes = computed(() => R.uniq(selectedFields.value.map((field: any) => field.type)));

    const isEmpty = computed(() => selectedFields.value.length === 0);

    const alreadyDefinedConstraintTypes = computed(() => {
        let array: any[] = [];
        selectedFields.value.forEach((field: any) => {
            array = array.concat(field.constraints);
        });
        return R.uniq(array.map((constraint: any) => constraint.type));
    });

    const areSelectedFieldsMultiple = computed(() => selectedFields.value.some((field: any) => field.multiple));

    // get fields that have the same type as the selected fields but are not selected
    const referencedFields = computed(() =>
        configuration.fields.filter(
            (configurationField: any) =>
                !selectedFields.value.find((field: any) => field.name === configurationField.name) &&
                !configurationField.multiple &&
                selectedFieldsTypes.value.includes(configurationField.type),
        ),
    );

    // get available constraint options based on selected field types
    const availableConstraintOptions = computed(() => {
        let options: ConstraintOptionsType[] = constraintOptions;

        // remove FOREIGN_KEY and CROSS_FIELD constraints if there are no referenced fields or if field is multiple
        if (referencedFields.value.length === 0 || areSelectedFieldsMultiple.value)
            options = options.filter(
                (option: any) =>
                    ![ConstraintType.FOREIGN_KEY, ConstraintType.NOT_FOREIGN_KEY, ConstraintType.CROSS_FIELD].includes(
                        option.id,
                    ),
            );

        // return only MANDATORY and UNIQUE constraints if selected fields do not have the same data type
        if (selectedFieldsTypes.value.length > 1) {
            if (selectedFieldsTypes.value.includes('boolean'))
                return options.filter((option: any) => option.id === ConstraintType.MANDATORY);
            return options.filter(
                (option: any) => option.id === ConstraintType.MANDATORY || option.id === ConstraintType.UNIQUE,
            );
        }

        // remove RANGE constraint for string selected fields
        if (selectedFieldsTypes.value.includes('string'))
            return options.filter(
                (option: any) =>
                    ![ConstraintType.RANGE, ConstraintType.NOT_RANGE, ConstraintType.RANGE_EXCLUDING].includes(
                        option.id,
                    ),
            );

        // return only MANDATORY, FOREIGN_KEY and CROSS_FIELD constraints for boolean selected fields
        if (selectedFieldsTypes.value.includes('boolean'))
            return options.filter((option: any) =>
                [
                    ConstraintType.MANDATORY,
                    ConstraintType.FOREIGN_KEY,
                    ConstraintType.NOT_FOREIGN_KEY,
                    ConstraintType.CROSS_FIELD,
                ].includes(option.id),
            );

        // remove REGULAR_EXPRESSION constraint for non-string selected fields
        if (selectedFieldsTypes.value.some((type: string) => type !== 'string'))
            return options.filter(
                (option: any) =>
                    ![ConstraintType.REGULAR_EXPRESSION, ConstraintType.NOT_REGULAR_EXPRESSION].includes(option.id),
            );

        return options;
    });

    return {
        selectedFieldsTypes,
        isEmpty,
        alreadyDefinedConstraintTypes,
        referencedFields,
        availableConstraintOptions,
    };
}
