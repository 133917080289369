import { Ref, computed, ref } from '@vue/composition-api';
import { ApiHarvesterConfiguration, ApiHarvesterHeader, ApiHarvesterParameter, ApolloTask } from '../types';
import { clone, difference, filter, isEmpty, isNil, pathEq, type } from 'ramda';
import { TaskStatus } from '../constants';
import { useAxios, useJsonObject } from '@/app/composable';
import { formatDate, getDynamicDate } from './transformations';
import { parseString, processors } from 'xml2js';
import { ApolloAPI } from '../api';

export function useAPIConnection(
    task: Ref<ApolloTask<ApiHarvesterConfiguration> | undefined>,
    loginResponse: Ref<Record<string, any> | undefined>,
    // fullSample: Ref<Record<string, any> | undefined>,
) {
    const { exec, loading: axiosLoading } = useAxios(true);
    const responsePrefix = 'res';
    const separator = '||';
    const { getSuperObject, convertToArray, convertToJSON } = useJsonObject();

    const composableLoading = ref<boolean>(false);
    const loading = computed(() => composableLoading.value || axiosLoading.value);

    /**
     * Crops response object/ array to specific size
     * @param response response object/ array to be cropped
     * @param size crop response object/ array to this size (defaulted to 10)
     */
    const limitResponse = (response: any, size = 10) => {
        if (type(response) === 'Array') {
            return response.slice(0, size);
        }

        if (type(response) === 'Object') {
            return Object.keys(response).reduce((result: any, key: string) => {
                Reflect.set(result, key, limitResponse(response[key], size));
                return result;
            }, {});
        }

        return response;
    };

    const extractApiInformation = (): { path: string; headers: any; payload: any } => {
        if (!task.value) throw new Error('No task defined');

        const now = new Date();
        const timezoneOffset = now.getTimezoneOffset();
        const utc = new Date(now.getTime() + timezoneOffset * 60 * 1000);
        const headers: Record<string, any> = {};

        let path = task.value.configuration.params.url;
        let { payload } = task.value.configuration.params;
        let queryPath = '?';

        for (const param of urlParams.value) {
            let value = param.value.value;
            if (param.value.category === 'authentication' && loginResponse.value && param.value.value)
                value = loginResponse.value[param.value.value.toString()];
            if (param.value.category === 'dynamic' && param.value.value)
                value = formatDate(
                    param.value.format!,
                    new Date(getDynamicDate(param.value.value['option'], utc, param.value.value['value'])),
                );
            path = path.replace(`{${param.key.name}}`, value as string);
        }

        for (const header of task.value.configuration.params.headers) {
            if (!header.key || !header.value) continue;

            let headerValue = header.value.trim();
            if (loginResponse.value) {
                for (const key of Object.keys(loginResponse.value)) {
                    headerValue = headerValue.replace(`{${key}}`, loginResponse.value[key]);
                }
            }
            headers[header.key] = headerValue;
        }

        for (const header of task.value.configuration.auth.headers) {
            headers[header.key] = header.value;
        }

        task.value.configuration.params.parameters.forEach((param: ApiHarvesterParameter) => {
            if (param.key.type === 'query') {
                if (param.value.value !== undefined && param.value.value !== null) {
                    const format = param.value.format ? param.value.format : null;
                    const dynamicParameterValue: { option: string; value?: string | undefined } = param.value.value as {
                        option: string;
                        value?: string | undefined;
                    };
                    const dynamicOption = dynamicParameterValue.option;
                    const dynamicValue = dynamicParameterValue.value ? parseInt(dynamicParameterValue.value, 10) : -1;
                    let { value } = param.value;
                    if (param.value.category === 'dynamic')
                        value = formatDate(format, new Date(getDynamicDate(dynamicOption, utc, dynamicValue)));
                    else if (param.value.category === 'authentication' && loginResponse.value)
                        value = loginResponse.value[`${value}`];
                    queryPath += `${param.key.name}=${value}&`;
                }
            } else if (task.value && task.value.configuration.params.method !== 'GET' && param.key.type === 'body') {
                if (param.value.value !== undefined && param.value.value !== null) {
                    const dynamicParameterValue: { option: string; value?: string | undefined } = param.value.value as {
                        option: string;
                        value?: string | undefined;
                    };
                    const format = param.value.format ? param.value.format : null;
                    const dynamicOption = dynamicParameterValue.option;
                    const dynamicValue = dynamicParameterValue.value ? parseInt(dynamicParameterValue.value, 10) : -1;
                    let { value } = param.value;
                    if (param.value.category === 'dynamic')
                        value = `"${formatDate(format, new Date(getDynamicDate(dynamicOption, utc, dynamicValue)))}"`;
                    else if (param.value.category === 'authentication' && loginResponse.value) {
                        value =
                            type(loginResponse.value[`${value}`]) === 'String'
                                ? `"${loginResponse.value[`${value}`]}"`
                                : loginResponse.value[`${value}`];
                    } else if (param.value.type === 'string') value = `"${value}"`;
                    payload = payload ? payload.replace(`{${param.key.name}}`, `${value}`) : null;
                }
            }
        });

        if (queryPath.length > 1) {
            queryPath = queryPath.slice(0, -1);
            path += queryPath;
        }

        path = path.split('+').join('%2B');

        if (isNil(task.value.configuration.params.payload) || isEmpty(task.value.configuration.params.payload)) {
            payload = '{}';
        }

        try {
            payload = payload ? JSON.parse(payload.replaceAll(/\{[A-Za-z0-9_-]+\}/g, '""')) : null;

            if (type(payload) === 'Array') {
                composableLoading.value = false;
                throw Error('Invalid payload');
            }

            task.value.configuration.params.parameters.forEach((param: ApiHarvesterParameter) => {
                if (param.key.type === 'body' && payload) {
                    payload[param.key.name] = param.value.value;
                }
            });
        } catch (e) {
            composableLoading.value = false;
            throw Error('Invalid payload');
        }

        return { path, headers, payload };
    };

    const executeApi = (): Promise<any[]> => {
        composableLoading.value = true;
        return new Promise((resolve, reject) => {
            if (!task.value) {
                reject();
                return;
            }

            const { path, headers, payload } = extractApiInformation();
            const config: any = {
                method: task.value.configuration.params.method,
                baseURL: path,
                headers,
                ignoreCertificates: task.value.configuration.params.ignoreCertificates,
            };
            config.headers.Accept = '*/*';
            config.data = payload ? JSON.stringify(payload) : null;
            exec(ApolloAPI.testAPI(config))
                .then((response: any) => {
                    composableLoading.value = false;
                    let { data } = response;
                    if (task.value?.configuration.fileType === 'xml') {
                        parseString(
                            data,
                            {
                                attrkey: '@',
                                charkey: '$',
                                explicitCharkey: false,
                                trim: true,
                                emptyTag: {},
                                explicitArray: false,
                                mergeAttrs: true,
                                attrNameProcessors: [
                                    (attrName: string) => {
                                        return `@${attrName}`;
                                    },
                                ],
                                attrValueProcessors: [processors.parseNumbers, processors.parseBooleans],
                                valueProcessors: [processors.parseNumbers, processors.parseBooleans],
                            },
                            (err, result) => {
                                if (!err) {
                                    data = result;
                                } else {
                                    reject({
                                        message: 'Invalid API response format',
                                        title: 'Failed: Invalid API response format',
                                        body: 'The response is not a valid XML format',
                                    });
                                }
                            },
                        );
                    } else if (task.value?.configuration.fileType === 'json') {
                        try {
                            if (typeof data !== 'string') {
                                data = JSON.stringify(data);
                            }
                            data = JSON.parse(data);
                        } catch (e) {
                            reject({
                                message: 'Invalid API response format',
                                title: 'Failed: Invalid API response format',
                                body: 'The response is not a valid JSON format',
                            });
                        }
                    }
                    const superObj = getSuperObject(clone(data), {});
                    const fixedData = convertToArray(clone(data), superObj);
                    const updatedData = limitResponse(fixedData, 10);

                    // const updatedDataJson = convertToJSON(clone(updatedData), responsePrefix, separator);
                    // const responseDataJson = convertToJSON(fullSample.value || {}, responsePrefix, separator);

                    // // only clear selected items if the response is different
                    // if (
                    //     !isOnUpdate.value &&
                    //     task.value?.configuration.response.selectedItems &&
                    //     !equals(updatedDataJson, responseDataJson)
                    // ) {
                    //     task.value.configuration.response.selectedItems.splice(0);
                    // }

                    resolve(updatedData);
                })
                .catch((error) => {
                    if (error.response)
                        reject({
                            message: 'API connection failed',
                            title: `Failed: ${error.response.status} ${error.response.statusText}`,
                            body: error.response.data ? error.response.data : null,
                            showIgnore:
                                error?.response?.data === 'SSL Error: self signed certificate in certificate chain',
                        });
                    else if (error.request)
                        reject({
                            message: 'API connection failed',
                            title: `Failed: API Connection Error`,
                            body: null,
                            showIgnore:
                                error?.response?.data === 'SSL Error: self signed certificate in certificate chain',
                        });
                    else {
                        reject({
                            message: 'API connection failed',
                            title: `Failed: API Connection Error`,
                            body: error.message,
                            showIgnore: error?.message === 'SSL Error: self signed certificate in certificate chain',
                        });
                    }

                    composableLoading.value = false;
                });
        });
    };

    const urlParams = computed(() =>
        task.value ? filter(pathEq(['key', 'type'], 'url'), task.value.configuration.params.parameters) : [],
    );

    const queryParams = computed(() =>
        task.value ? filter(pathEq(['key', 'type'], 'query'), task.value.configuration.params.parameters) : [],
    );

    const bodyParams = computed(() =>
        task.value ? filter(pathEq(['key', 'type'], 'body'), task.value.configuration.params.parameters) : [],
    );

    const hasDuplicateParams = () => {
        const urlKeyNames = urlParams.value.map((param: any) => param.key);
        const urlDuplicates = urlKeyNames.filter((param: any, index: number) => urlKeyNames.indexOf(param) !== index);

        const queryKeyNames = queryParams.value.map((param: any) => param.key.name);
        const queryDuplicates = queryKeyNames.filter(
            (param: any, index: number) => queryKeyNames.indexOf(param) !== index,
        );

        const bodyKeyNames = bodyParams.value.map((param: any) => param.key.name);
        const bodyDuplicates = bodyKeyNames.filter(
            (param: any, index: number) => bodyKeyNames.indexOf(param) !== index,
        );
        if (urlDuplicates.length > 0 || queryDuplicates.length > 0 || bodyDuplicates.length > 0) return true;
        return false;
    };

    const validate = () => {
        if (hasDuplicateParams()) throw Error('Duplicate parameters');
        if (urlParams.value.some((param: ApiHarvesterParameter) => isNil(param.value.value)))
            throw Error('Missing URL parameter value(s)');
        if (queryParams.value.some((param: ApiHarvesterParameter) => isNil(param.value.value)))
            throw Error('Missing query parameter value(s)');
        if (task.value?.configuration.params.headers.some((header: ApiHarvesterHeader) => isNil(header.value)))
            throw Error('Missing header value(s)');
        // !isLoginTested();
        return true;
    };

    const isOnUpdate = computed(() => task.value?.status === TaskStatus.Updating);

    const test = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (!task.value) {
                reject({ message: 'Task not defined' });
                return;
            }

            if (
                !task.value.configuration.params.url.startsWith('http://') &&
                !task.value.configuration.params.url.startsWith('https://')
            ) {
                task.value.configuration.params.url = `http://${task.value.configuration.params.url}`;
            }
            try {
                executeApi()
                    .then((apiData: any) => {
                        if (task.value && apiData && isOnUpdate && task.value.configuration.response.jsonResponse) {
                            const originalResponse = task.value.configuration.response.jsonResponse;
                            const jsonResponse = convertToJSON(apiData, responsePrefix, separator);

                            const deepFlattenToArray = (obj: any, prefix = '', seperator = '||') => {
                                return Object.keys(obj).reduce((acc: string[], k: string) => {
                                    if (typeof obj[k] === 'object' && obj[k] !== null) {
                                        acc = [...acc, ...deepFlattenToArray(obj[k], prefix + k + seperator)];
                                    } else {
                                        acc.push(prefix + k + '__' + obj[k]);
                                    }
                                    return acc;
                                }, []);
                            };

                            const originalResponseFlatten = deepFlattenToArray(originalResponse);
                            const jsonResponseFlatten = deepFlattenToArray(jsonResponse);
                            const diff = difference(originalResponseFlatten, jsonResponseFlatten);

                            if (diff.length === originalResponseFlatten.length) {
                                reject({ noMatch: true, sample: apiData });
                                return;
                            } else if (diff.length) {
                                reject({ partialMatch: true, sample: apiData });
                                return;
                            }
                        }
                        resolve(apiData);
                    })
                    .catch((e: any) => reject(e));
            } catch (e: any) {
                reject({ message: e });
            }
        });
    };

    return { test, validate, executeApi, separator, responsePrefix, loading };
}
