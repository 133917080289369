import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { TaskExecutionStatus, WorkflowExecutionStatus, WorkflowStatus } from '../constants';
import { ApolloTask } from './apollo-task.type';

export type ApolloPipeline = {
    name: string;
    description: string;
    status?: WorkflowStatus;
    runnerId: string | null;
    policies: Array<any>;
    accessLevel: AccessLevel;
    createdById?: number;
    provenanceAssetIds?: number[];
    tasks: ApolloTask[];
};

export type IndexedApolloPipelineTask = {
    id: string;
    type: string;
    status: TaskExecutionStatus;
    order: number;
};

export type IndexedApolloPipeline = {
    id: string;
    name: string;
    description?: string;
    type: 'data-checkin' | 'analytics';
    framework: string;
    executionStatus: WorkflowExecutionStatus;
    accessLevel: AccessLevel;
    schedules?: {
        startDate: Date;
        endDate: Date;
    }[];
    executionLocation: string;
    storageLocation: string;
    createdAt: string;
    updatedAt: string;
    createdBy: {
        id: number;
        firstName: string;
        lastName: string;
    };
    organisation?: {
        id: number;
    };
    harvesterSource?: IndexedHarvesterSourceType | null;
    retrievalType?: string;
    retrieveUntil?: Date;
    fileType?: string;
    provenanceAssetIds?: number[];
    tasks: IndexedApolloPipelineTask[];
    hasExecuted: boolean;
    canUse: boolean;
};

export enum IndexedHarvesterSourceType {
    File = 'file',
    Api = 'api',
    InternalApi = 'internalApi',
    Streaming = 'streaming',
    ExternalStreaming = 'externalStreaming',
    Kafka = 'kafka',
    LargeFiles = 'largeFiles',
    ExternalKafka = 'externalKafka',
    MQTT = 'mqtt',
    ExternalMQTT = 'externalMqtt',
    BigQuery = 'bigQuery',
    SQL = 'sql',
    SAPHana = 'sapHana',
}
