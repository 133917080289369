import { Ref, ref } from '@vue/composition-api';
import { ApiHarvesterAuthenticationConfiguration } from '../types';
import { useAxios } from '@/app/composable';
import { ApolloAPI } from '../api';

export function useApolloHarvesterLogin(authentication: Ref<ApiHarvesterAuthenticationConfiguration>) {
    const loading = ref<boolean>(false);
    const invalidLoginBody = ref<boolean>(false);

    const { exec } = useAxios(true);

    const login = () =>
        new Promise((resolve, reject) => {
            if (!authentication.value.url)
                reject({
                    title: 'Failed to test login',
                    body: 'No authentication URL defined',
                    showIgnore: false,
                    invalidLoginBody: false,
                });
            loading.value = true;
            invalidLoginBody.value = false;
            let payload = null;
            if (
                authentication.value.url &&
                !authentication.value.url.startsWith('http://') &&
                !authentication.value.url.startsWith('https://')
            ) {
                authentication.value.url = `http://${authentication.value.url}`;
            }

            if (authentication.value.payload)
                try {
                    payload = JSON.parse(authentication.value.payload);
                } catch (e) {
                    reject({
                        title: 'Failed to test login',
                        body: 'No authentication URL defined',
                        showIgnore: false,
                        invalidLoginBody: true,
                    });
                } finally {
                    loading.value = false;
                }

            const headers: Record<string, any> = {};
            if (authentication.value.method === 'keycloak') {
                headers['Content-Type'] = 'application/x-www-form-urlencoded';
            }

            exec(
                ApolloAPI.testAPI({
                    method: 'POST',
                    baseURL: authentication.value.url as string,
                    data: payload,
                    ignoreCertificates: authentication.value.ignoreCertificates,
                    headers: headers,
                }),
            )
                .then((response: any) => {
                    loading.value = false;
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        reject({
                            title: `Failed: ${error.response.status} ${error.response.statusText}`,
                            body: error.response.data ? error.response.data : null,
                            showIgnore:
                                error?.response?.data === 'SSL Error: self signed certificate in certificate chain',
                            invalidLoginBody: false,
                        });
                    } else if (error.request) {
                        // Axios exception
                        reject({
                            title: 'Login Failed: Connection Error',
                            body: null,
                            showIgnore: false,
                            invalidLoginBody: false,
                        });
                    } else {
                        // Other error
                        reject({
                            title: 'Login Failed: Connection Error',
                            body: error.message,
                            showIgnore: error.message === 'SSL Error: self signed certificate in certificate chain',
                            invalidLoginBody: false,
                        });
                    }
                })
                .finally(() => (loading.value = false));
        });

    return { login, invalidLoginBody, loading };
}
