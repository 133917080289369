import * as R from 'ramda';
import { AlternateNaming } from '../constants';
import { FieldConfiguration, MappedFieldConfiguration, MappingConfiguration, Target } from '../types';
import { FieldType, MappingFieldConfiguration, ValuesNonNullable } from '../types/typings';

export function useSampleFields() {
    /**
     * Extract an array of values, using the provided sample
     * @param title The title of the field to search for
     * @param path The path of the field to search for
     */
    const extractFieldSample = (sample: any[], title: string, path: string[]): any[] => {
        const finalPaths: any[] = [[]]; // Contains the paths to get samples from every record
        path.forEach((subPath: any) => {
            if (subPath.endsWith('[]')) {
                finalPaths.forEach((fPath: any) => {
                    fPath.push(subPath.replaceAll('[]', '')); // Remove [] from subPath
                    const subPathSamples: any[] = R.map(R.path(fPath), sample); // Contains the samples from every record for subPath
                    let subPathSamplesLength = 0;
                    subPathSamples.forEach((subPathSample: any) => {
                        // add nested paths in case of array in array
                        let arraysCount = 0;
                        while (R.is(Array, R.map(R.path(fPath), sample)[0])) {
                            fPath.push(0);
                            arraysCount += 1;

                            if (arraysCount > 1) subPathSample = subPathSample[0];
                        }

                        if (subPathSample && subPathSample.length > subPathSamplesLength) {
                            subPathSamplesLength = subPathSample.length;
                        }
                    });

                    // Calculate all the paths based on subPath's sample length
                    for (let i = 1; i < subPathSamplesLength; i += 1) {
                        const newPath = R.clone(fPath);
                        newPath.pop();
                        newPath.push(i);
                        finalPaths.push(newPath);
                    }
                });
            } else {
                // Add subPath to all paths
                finalPaths.forEach((p: any) => p.push(subPath));
            }
        });

        // Add title to all paths
        finalPaths.forEach((p: any) => p.push(title));

        // Find the sample values of every path
        const allSamples: any[][] = [];
        finalPaths.forEach((fPath: any[]) => {
            let fPathSample = R.map(R.path(fPath), sample);
            fPathSample = fPathSample.filter((value: any) => value !== undefined);
            allSamples.push(fPathSample);
        });
        /**
         * Extract the values of each row
         * e.g. from [[1,3], [2,4]] => [1,2,3,4]
         */
        const sampleValuesPerRow = R.transpose(allSamples);
        return sampleValuesPerRow.reduce((acc: any[], current: any) => acc.concat(current), []);
    };

    const fieldIsMapped = (field: FieldConfiguration): field is MappedFieldConfiguration => !!field.target?.id;

    const getFullFieldName = (field: MappedFieldConfiguration) =>
        [...field.target.path, field.target.title].map((pathField) => pathField.replace('[]', '__0')).join('__');

    /**
     * Constructs and returns the fields with the new names after mapping.
     * @param fields The list of fields before mapping
     */
    const extractMappingFieldNames = (fields: FieldConfiguration[]): MappingFieldConfiguration[] => {
        const mappedFields = fields.filter(fieldIsMapped);
        return R.uniqBy((field) => [...field.target.path, field.target.title], mappedFields).map(
            (field): MappingFieldConfiguration => ({
                ...field.target,
                id: field.target.id as number,
                type: field.target.type as FieldType,
                multiple: !!field.transformation?.multiple,
                order: field.transformation?.order,
                name: getFullFieldName(field),
                modified: field.temp?.modified,
                originalName: field.source.title,
                originalPath: field.source.path,
                alias: field.alias,
                indexES: field.metadata?.indexES,
            }),
        );
    };

    /**
     * Constructs and returns an object containing the alternate name (title and path) of each mapped field.
     * @param mappingConfig the mapping configuration
     * @returns the alternate naming schema
     */
    const extractAlternateNames = (mappingConfig: MappingConfiguration) => {
        if (mappingConfig.alternateNaming === AlternateNaming.None) return null;

        const mappedFields = mappingConfig.fields.filter(fieldIsMapped);
        // sort fields based on order, so
        const orderedMappedFields = R.sort(R.ascend<any>(R.path(['transformation', 'order'])), mappedFields);

        return orderedMappedFields.reduce((altNames, field) => {
            if (!altNames[field.target.id]) {
                // initialise altername name for that target field
                altNames[field.target.id] = {
                    // title is array in case multiple ordered fields are mapped to the same target
                    title: [],
                    path: field.target.path,
                };
            }

            let newTitle = '';
            if (mappingConfig.alternateNaming === AlternateNaming.Original) {
                // the alternate title is the SOURCE title
                altNames[field.target.id].path = field.source.path;
                newTitle = field.source.title;
            } else if (mappingConfig.alternateNaming === AlternateNaming.Alias && field.alias) {
                // the altername title is the ALIAS given for that field
                newTitle = field.alias;
            } else {
                // the alternate title is the actual TARGET title
                newTitle = field.target.title;
            }

            altNames[field.target.id].title.push(newTitle);

            return altNames;
        }, {} as Record<string, { title: string[]; path: string[] }>);
    };

    return { extractFieldSample, extractMappingFieldNames, extractAlternateNames, fieldIsMapped };
}
