import { Component } from 'vue';
import { PreprocessingBlockId } from '../constants';
import { Stats, StatsPerField } from './task-stats.type';

export type IdentifierAnonymisationMethod = 'drop' | 'hide';

export type NullValues = {
    keep: boolean;
    replaceWith?: { hours: number; minutes: number; seconds: number } | string | number | null | undefined;
};

export type NumericalFieldLeveling = 'auto' | 'custom';

export type IdentifierOptionsField = {
    anonymisationMethod: IdentifierAnonymisationMethod;
};

export type IntervalOptionsField = {
    leveling: NumericalFieldLeveling;
    levels: { interval: number | null }[];
    nullValues: NullValues;
};

export type NumericalGroupOptionsField = {
    leveling: 'auto' | 'custom';
    levels: { from: number | null; to: number | null; label?: string | null }[][];
    nullValues: NullValues;
};

export type DateOptionsField = {
    finalLevel: number;
    nullValues: NullValues;
};

export type BooleanOptionsField = {
    show: boolean;
    nullValues: NullValues;
};

export type MaskingOptionsField = {
    maskingChar: string;
    paddingChar: string;
    paddingDirection: string;
    maskingDirection: string;
    nullValues: NullValues;
};

export type QuasiIdentifierOptionsField =
    | IntervalOptionsField
    | NumericalGroupOptionsField
    | DateOptionsField
    | BooleanOptionsField
    | MaskingOptionsField;

export type AnonymisationType = 'insensitive' | 'identifier' | 'quasi-identifier' | 'sensitive';
export type GeneralisationMethod =
    | 'interval'
    | 'numerical-group'
    | 'masking'
    | 'boolean-group'
    | 'datetime'
    | 'date'
    | 'time';

export type AnonymisableFieldDatatype = 'string' | 'double' | 'integer' | 'date' | 'time' | 'datetime' | 'boolean';

export type AnonymisationFieldDatatype = AnonymisableFieldDatatype | 'base64binary';

export interface AnonymisationField {
    id: number;
    anonymisationIdentifier: string;
    type: AnonymisationFieldDatatype;
    name: string;
    originalName: string;
    anonymisationType: AnonymisationType;
    generalisation?: GeneralisationMethod;
    options?: IdentifierOptionsField | QuasiIdentifierOptionsField;
}

export interface IdentifierAnonymisationField extends AnonymisationField {
    id: number;
    type: AnonymisationFieldDatatype;
    name: string;
    originalName: string;
    anonymisationType: AnonymisationType;
    generalisation: GeneralisationMethod;
    options: IdentifierOptionsField;
}

export interface IntervalAnonymisationField extends AnonymisationField {
    id: number;
    type: AnonymisationFieldDatatype;
    name: string;
    originalName: string;
    anonymisationType: AnonymisationType;
    generalisation: GeneralisationMethod;
    options: IntervalOptionsField;
}

export interface NumericalGroupAnonymisationField extends AnonymisationField {
    id: number;
    type: AnonymisationFieldDatatype;
    name: string;
    originalName: string;
    anonymisationType: AnonymisationType;
    generalisation: GeneralisationMethod;
    options: NumericalGroupOptionsField;
}

export interface DateAnonymisationField extends AnonymisationField {
    id: number;
    type: AnonymisationFieldDatatype;
    name: string;
    originalName: string;
    anonymisationType: AnonymisationType;
    generalisation: GeneralisationMethod;
    options: DateOptionsField;
}

export interface BooleanAnonymisationField extends AnonymisationField {
    id: number;
    type: AnonymisationFieldDatatype;
    name: string;
    originalName: string;
    anonymisationType: AnonymisationType;
    generalisation: GeneralisationMethod;
    options: BooleanOptionsField;
}
export interface MaskingAnonymisationField extends AnonymisationField {
    id: number;
    type: AnonymisationFieldDatatype;
    name: string;
    originalName: string;
    anonymisationType: AnonymisationType;
    generalisation: GeneralisationMethod;
    options: MaskingOptionsField;
}

export type QuasiIdentifierAnonymisationField =
    | IntervalAnonymisationField
    | NumericalGroupAnonymisationField
    | DateAnonymisationField
    | BooleanAnonymisationField
    | MaskingAnonymisationField;

export type NumericLevelAnonymisationField = IntervalAnonymisationField | NumericalGroupAnonymisationField;

export interface AnonymisationConfiguration {
    fields: AnonymisationField[];
    kAnonymity: number;
    lossLimit: number;
    hasChangesAfterRevise?: boolean;
}

export type AnonymisationConfigurationType = AnonymisationConfiguration;
export type AnonymisationFieldType = AnonymisationField;

export enum AnonymisationStep {
    Configuration,
    SamplePreview,
    Confirm,
}

export type AnonymisationStepType = { key: AnonymisationStep; name: string; component: Component; scrollable: boolean };

export type AnonymisationTask = { blockId: PreprocessingBlockId.Anonymisation; steps: AnonymisationStepType[] };

export interface AnonymisationFieldStatistics extends StatsPerField {
    anonymisationGeneralisation: GeneralisationMethod;
    anonymisationGeneralisationLevel: number;
    anonymisationIdentifier: string;
    anonymisationInformationLoss: number;
}

export interface AnonymisationStatistics extends Stats<AnonymisationFieldStatistics> {
    anonymisationTotalInformationLoss: number;
}
