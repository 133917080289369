import { Component } from 'vue';
import { PreprocessingBlockId } from '../constants';
import { FieldConfiguration } from './typings';

export enum ConstraintType {
    RANGE = 'RANGE',
    RANGE_EXCLUDING = 'RANGE_EXCLUDING',
    NOT_RANGE = 'NOT_RANGE',
    MANDATORY = 'MANDATORY',
    REGULAR_EXPRESSION = 'REGULAR_EXPRESSION',
    NOT_REGULAR_EXPRESSION = 'NOT_REGULAR_EXPRESSION',
    UNIQUE = 'UNIQUE',
    SET_MEMBERSHIP = 'SET_MEMBERSHIP',
    CROSS_FIELD = 'CROSS_FIELD',
    FOREIGN_KEY = 'FOREIGN_KEY',
    NOT_FOREIGN_KEY = 'NOT_FOREIGN_KEY',
}

export enum OutliersRuleType {
    DROP = 'DROP',
    DEFAULT_VALUE = 'DEFAULT_VALUE',
    PREVIOUS_VALUE = 'PREVIOUS_VALUE',
    MOST_FREQUENT_VALUE = 'MOST_FREQUENT_VALUE',
    MEAN_VALUE = 'MEAN_VALUE',
    MEDIAN_VALUE = 'MEDIAN_VALUE',
    MIN_VALUE = 'MIN_VALUE',
    MAX_VALUE = 'MAX_VALUE',
    MOST_SIMILAR = 'MOST_SIMILAR',
}

export enum SecondaryRuleType {
    DROP = 'DROP',
    DEFAULT_VALUE = 'DEFAULT_VALUE',
}

export interface SecondaryRule {
    type: SecondaryRuleType | null;
    replaceValue: string | number | boolean | null;
}

export interface OutliersRule {
    type: OutliersRuleType | null;
    replaceValue: string | number | boolean | null;
    secondaryRule: SecondaryRule | null;
}

export interface Condition {
    fieldId?: number | null;
    fieldName?: string;
    type?: ConstraintType | null;
    details?: object | null;
    conditionUid?: string | null;
    operator?: string | null;
    conditions?: Condition[];
}

export interface Constraint {
    id: number;
    referenceId: number;
    type?: ConstraintType | null;
    details?: Record<string, any> | null;
    structure?: {
        operator: string;
        conditions: Condition[];
    } | null;
    outliersRule: OutliersRule | null;
    fieldName: string | string[];
    parentFieldName?: string | string[];
}

export type CleaningFieldConfiguration = FieldConfiguration & {
    constraints: Constraint[];
};

export interface CleaningConfiguration {
    fields: CleaningFieldConfiguration[];
    emptySample?: boolean;
    hasChangesAfterRevise?: boolean;
}

export interface ConstraintStats {
    id: number;
    error_code: number | null;
    dropped: number;
    null: number;
    transformed: number;
}

export type CleaningConfigurationType = CleaningConfiguration;

export enum ConstraintMode {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
}

export enum CleaningStep {
    Configuration,
    SamplePreview,
    Confirm,
}

export type CleaningStepType = { key: CleaningStep; name: string; component: Component; scrollable: boolean };

export type CleaningTask = { blockId: PreprocessingBlockId.Cleaning; steps: CleaningStepType[] };
