import {
    ApiPaginationOptions,
    FileType,
    HarvesterBlockId,
    KafkaProcessingOptions,
    KafkaSaslMechanism,
    KafkaSecurityProtocol,
    RetrievalType,
    SQLDatabaseType,
    SQLRetrievalMethod,
} from '../constants';
import { TaskStep } from './apollo-task.type';

export type ApiHarvesterParameter = {
    key: {
        name: string;
        type: 'query' | 'url' | 'body';
    };
    value: {
        value: string | number | { option: string | undefined; value: string | undefined } | undefined;
        category: 'static' | 'dynamic' | 'authentication' | 'pagination' | undefined;
        type?: 'integer' | 'float' | 'string' | undefined;
        format?: string | undefined;
    };
    paginateWith?: 'limit' | 'page' | 'offset' | undefined;
    sensitive: boolean;
    optional?: boolean;
};

export type HarvesterResponseConfiguration = {
    basePath: string;
    multiple: boolean;
    selectedItems: string[];
    additional?: {
        key: string;
        value?: string | ApiHarvesterParameter;
        type: 'static' | 'dynamic';
        displayValue: string;
    }[];
    jsonResponse?: Record<string, string | number>; // structure with key and type
};

export interface KafkaConnectionDetails {
    username: string | null;
    password: string | null;
    url: string | null;
    topic: string | null;
    groupId?: string | null;
    securityProtocol: KafkaSecurityProtocol | null;
    saslMechanism: KafkaSaslMechanism | null;
}

export interface FileHarvesterConfiguration {
    fileType: FileType | null;
    params: {
        delimiter?: string;
        linebreak?: string;
        fields?: string[];
    };
    isSampleCropped: boolean;
    isSampleUploaded: boolean;
    inputFilename: string;
    response: HarvesterResponseConfiguration;
    sample: Array<Record<string, any>> | null;
}

export type ApiHarvesterAuthenticationMethod = 'no' | 'bearer' | 'keycloak' | 'custom';
export type ApiHarvesterAuthenticationTokenType = 'Bearer' | 'JWT' | 'Token';

export type ApiHarvesterAuthenticationConfiguration = {
    method: ApiHarvesterAuthenticationMethod | null;
    url: string | null;
    payload: string | null;
    tokenType: ApiHarvesterAuthenticationTokenType | null;
    accessToken: string | null;
    headers: ApiHarvesterHeader[];
    ignoreCertificates: boolean;
};

export type ApiHarvesterMethod = 'GET' | 'POST' | 'PUT';

export type ApiHarvesterHeader = {
    key: string;
    value: string | null;
    sensitive: boolean;
};

export type ApiHeaderTagConfig = {
    text: string;
    tiClasses: string[];
    classes: string;
};

export type ApiHeaderTag = {
    value: string;
    tags: ApiHeaderTagConfig[];
};

export type ApiHarvesterParams = {
    method: ApiHarvesterMethod;
    url: string;
    headers: ApiHarvesterHeader[];
    headerTags: ApiHeaderTag[];
    payload: string | null;
    parameters: ApiHarvesterParameter[];
    ignoreCertificates: boolean;
};

export type ApiPaginationOption = typeof ApiPaginationOptions[number];

export type ApiHarvesterPagination = {
    type: ApiPaginationOption;
};

export type HarvesterRetrievalConfiguration = {
    type?: RetrievalType | undefined;
    endDate: Date | null;
};

export interface ApiHarvesterConfiguration {
    fileType: FileType | null;
    params: ApiHarvesterParams;
    pagination: ApiHarvesterPagination;
    auth: ApiHarvesterAuthenticationConfiguration;
    retrieval: HarvesterRetrievalConfiguration;
    response: HarvesterResponseConfiguration;
    sample?: any;
}

export interface OtherFileHarvesterConfiguration {
    fileType: FileType.Other;
}

export interface ExternalKafkaHarvesterConfiguration {
    fileType: FileType;
    processing: KafkaProcessingOptions;
    isSampleCropped: boolean;
    connectionDetails: KafkaConnectionDetails;
    retrieval: {
        endDate: Date | null;
    };
    response: {
        basePath: string;
        multiple: boolean;
        selectedItems: string[];
    };
    sample?: Array<Record<string, any>> | null;
}

export interface KafkaHarvesterConfiguration {
    fileType: FileType;
    processing: KafkaProcessingOptions;
    isSampleCropped: boolean;
    connectionDetails: KafkaConnectionDetails;
    retrieval: {
        endDate: Date | null;
    };
    response: {
        basePath: string;
        multiple: boolean;
        selectedItems: string[];
    };
    sample?: Array<Record<string, any>> | null;
}

export interface OtherFileHarvesterConfiguration {
    fileType: FileType.Other;
}

export interface MqttConnectionDetails {
    protocolVersion: string;
    url: string;
    username: string;
    password: string;
    clientId: string;
    securityProtocol: string;
    topic: string;
    topics: {
        name: string;
        qos: number;
    }[];
}

export interface MqttHarvesterConfiguration {
    fileType: 'xml' | 'json';
    topicNameField: string | null;
    isSampleCropped: boolean;
    connectionDetails: MqttConnectionDetails;
    retrieval: {
        endDate: Date;
    };
    sample: Array<Record<string, any>> | null;
}

export interface ExternalMqttHarvesterConfiguration extends MqttHarvesterConfiguration {
    response: {
        basePath: string;
        multiple: boolean;
        selectedItems: string[];
        additional: { key: string; value: string; type: string }[];
    };
}

export interface InternalApiHarvesterConfiguration {
    fileType: 'json';
    dataType: 'text' | 'textBinary';
    isSampleCropped: boolean;
    sample: Array<Record<string, any>> | null;
}

export interface AdditionalResponseDataType {
    key: string;
    displayValue: string;
    dynamicType?: string;
    format?: string;
    type: 'static' | 'dynamic' | 'request';
    value: string | ApiHarvesterParameter;
}

export interface LargeFilesConnectionDetails {
    url: string;
    accessKey: string;
    secretKey?: string;
}

export interface LargeFilesHarvesterConfiguration {
    fileType: FileType.CSV | FileType.Parquet;
    params: {
        delimiter?: string;
        linebreak?: string;
        fields: string[];
    };
    isSampleCropped: boolean;
    connectionDetails: LargeFilesConnectionDetails;
    retrieval: {
        type: string;
    };
    response: {
        additional: AdditionalResponseDataType[];
    };
    sample?: Array<Record<string, any>> | null;
}

export interface BigQueryAuthentication {
    serviceAccountKey: {
        type: 'json';
        key: string | null;
    };
}

export interface BigQueryHarvesterConfiguration {
    auth: BigQueryAuthentication;
    method: SQLRetrievalMethod;
    query: string | null;
    table: string | null;
    freshDataColumn: string | null;
    retrieval: {
        type: RetrievalType;
    };
    additional: AdditionalResponseDataType[];
    sample: Array<Record<string, any>> | null;
}

export interface SQLConnectionDetails {
    host: string | null;
    port: number | null;
    username: string | null;
    password: string | null;
    database: string | null;
}

export interface SQLHarvesterConfiguration {
    sqlType: SQLDatabaseType;
    connection: SQLConnectionDetails;
    method: SQLRetrievalMethod;
    query: string | null;
    table: string | null;
    identifierColumn: string | null;
    retrieval: {
        type: RetrievalType;
    };
    additional: AdditionalResponseDataType[];
    sample: Array<Record<string, any>> | null;
}

export interface SAPHanaHarvesterConfiguration {
    connection: SQLConnectionDetails;
    method: SQLRetrievalMethod;
    query: string | null;
    table: string | null;
    databaseSchema: string | null;
    identifierColumn: string | null;
    retrieval: {
        type: RetrievalType;
    };
    additional: AdditionalResponseDataType[];
    sample: Array<Record<string, any>> | null;
}

export type HarvesterConfiguration =
    | FileHarvesterConfiguration
    | OtherFileHarvesterConfiguration
    | ApiHarvesterConfiguration
    | LargeFilesHarvesterConfiguration
    | KafkaHarvesterConfiguration
    | ExternalKafkaHarvesterConfiguration
    | MqttHarvesterConfiguration
    | ExternalMqttHarvesterConfiguration
    | InternalApiHarvesterConfiguration
    | BigQueryHarvesterConfiguration
    | SQLHarvesterConfiguration
    | SAPHanaHarvesterConfiguration;

export enum HarvesterStep {
    Setup,
    Review,
}

export type HarvesterTask = {
    blockId: HarvesterBlockId;
    component: any;
    steps: TaskStep[];
};

export type TFile = File & { filename?: string; status?: string };

export interface FilesData {
    sampleFile: TFile | null;
    dataFile: TFile | null;
}

export interface PostPolicyData {
    folder: string;
    subfolder?: string;
}

export interface PostPolicy {
    prefix: string;
}

export interface PostPolicyFile {
    id?: string;
    filename: string;
    mimeType: string;
    path: string;
    size: number;
}
